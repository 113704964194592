exports.components = {
  "component---src-pages-dvere-sapeli-js": () => import("./../../../src/pages/dvere-sapeli.js" /* webpackChunkName: "component---src-pages-dvere-sapeli-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-1-js": () => import("./../../../src/pages/kontakt-1.js" /* webpackChunkName: "component---src-pages-kontakt-1-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nasesluzby-js": () => import("./../../../src/pages/nasesluzby.js" /* webpackChunkName: "component---src-pages-nasesluzby-js" */),
  "component---src-pages-ohlasy-a-recenze-js": () => import("./../../../src/pages/ohlasy-a-recenze.js" /* webpackChunkName: "component---src-pages-ohlasy-a-recenze-js" */),
  "component---src-pages-zaluzie-js": () => import("./../../../src/pages/zaluzie.js" /* webpackChunkName: "component---src-pages-zaluzie-js" */)
}

